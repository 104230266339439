
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import {
  BaseIcon,
  BaseButton,
  BaseTextInput,
  BaseTextarea,
  BaseModal,
  ResolveReason
} from '@/lib/components';
import {
  Consult,
  ExternalPatientReference,
  Patient,
  ResolveReasonValue
} from '@/models';
import { ConsultService } from '@/services/api';
import { UHB_NHS_NUMBER_KEY } from '@/constants';
import { formattedNHSNumber } from '@/lib/helpers/nhs-number.helper';
import { getDobFromISOString } from '@/helpers/patient.helper';

@Options({
  props: {
    consult: {
      type: Object,
      default: () => ({})
    },
    patient: {
      type: Object,
      default: () => ({})
    },
    error: {
      type: String,
      default: ''
    }
  },
  components: {
    BaseIcon,
    BaseModal,
    BaseButton,
    BaseTextInput,
    BaseTextarea,
    ResolveReason
  }
})
export default class ResolveReasonModal extends Vue {
  consult!: Consult;
  patient!: Patient;
  error!: string;
  loading = false;
  resolveReason: ResolveReasonValue = {
    selectedReason: '',
    additionalReason: ''
  };

  consultService = new ConsultService();
  rejectReasonError: string = this.error;

  get patientNHSNumber(): string {
    if (this.patient && this.patient.external_patient_references) {
      const nhsEpr = this.patient.external_patient_references.find(
        (epr: ExternalPatientReference) =>
          epr.type ? epr.type.key === UHB_NHS_NUMBER_KEY : false
      );

      if (nhsEpr && nhsEpr.value) {
        return formattedNHSNumber(nhsEpr.value);
      }
    }
    return this.$t('platform.common.none').toString();
  }

  get today() {
    return dayjs().format('YYYY-MM-DD');
  }

  close() {
    this.$emit('updateErrorMessage', '');
    this.$emit('close');
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'short');
  }

  markAsResolved() {
    this.$emit('markAsResolved', { ...this.resolveReason });
  }

  onChange(value: ResolveReasonValue) {
    this.resolveReason = value;
  }

  updateError(value: number) {
    this.$emit(
      'updateErrorMessage',
      value < 0
        ? this.$t('custom.uhb.consult.error-additional-reason-limit')
        : ''
    );
  }
}
