import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "w-148 p-10 mx-auto bg-white rounded-lg" }
const _hoisted_2 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_3 = { class: "bg-gray-100 mt-4 rounded-lg" }
const _hoisted_4 = { class: "flex justify-between" }
const _hoisted_5 = { class: "ml-4 mb-2 pt-4 text-gray-900 text-lg font-semibold" }
const _hoisted_6 = { class: "capitalize" }
const _hoisted_7 = { class: "grid grid-cols-1 ml-4 gap-0 text-sm font-medium" }
const _hoisted_8 = { class: "grid grid-cols-2" }
const _hoisted_9 = { class: "grid grid-cols-3 py-2 border-t border-gray-300" }
const _hoisted_10 = { class: "col-span-1 text-gray-500" }
const _hoisted_11 = { class: "col-span-2 text-gray-900" }
const _hoisted_12 = { class: "grid grid-cols-3 py-2 mr-4 border-t border-gray-300" }
const _hoisted_13 = { class: "col-span-1 text-gray-500" }
const _hoisted_14 = { class: "col-span-2 space-x-2" }
const _hoisted_15 = { class: "col-span-5 text-gray-900" }
const _hoisted_16 = { class: "grid grid-cols-2" }
const _hoisted_17 = { class: "grid grid-cols-3 py-2 border-t border-gray-300" }
const _hoisted_18 = { class: "col-span-1 text-gray-500" }
const _hoisted_19 = { class: "col-span-2 text-gray-900" }
const _hoisted_20 = { class: "grid grid-cols-3 py-2 mr-4 border-t border-gray-300" }
const _hoisted_21 = { class: "col-span-1 text-gray-500" }
const _hoisted_22 = { class: "col-span-2 space-x-2" }
const _hoisted_23 = { class: "col-span-5 text-gray-900" }
const _hoisted_24 = { class: "grid grid-cols-6 py-2 mr-4 border-t border-gray-300" }
const _hoisted_25 = { class: "col-span-1 text-gray-500" }
const _hoisted_26 = { class: "col-span-5 text-gray-900" }
const _hoisted_27 = { class: "space-y-6" }
const _hoisted_28 = { class: "grid grid-cols-2 gap-4 pt-6" }
const _hoisted_29 = { class: "space-y-2" }
const _hoisted_30 = {
  key: 0,
  class: "text-sm font-semibold leading-none text-danger-600"
}
const _hoisted_31 = { class: "mt-8 flex justify-start w-full items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseTextarea = _resolveComponent("BaseTextarea")!
  const _component_ResolveReason = _resolveComponent("ResolveReason")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _directive_allow = _resolveDirective("allow")!

  return (_openBlock(), _createBlock(_component_BaseModal, { onClose: _ctx.close }, {
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("custom.uhb.consult.resolve-rejected")), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.patient.last_name), 1),
              _createTextVNode(", " + _toDisplayString(_ctx.patient.first_name), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('platform.common.id')), 1),
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.consult.patient_mrn_at_clinic_owner), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t(`custom.uhb.patient.nhs`)), 1),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_BaseIcon, { name: "nhs-logo" }),
                  _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.patientNHSNumber), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("platform.patient.phone")), 1),
                _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.patient.contact_number), 1)
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t("platform.patient.dob")), 1),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.formatDob(_ctx.patient.date_of_birth)) + " (" + _toDisplayString(_ctx.$t("platform.patient.years", { age: _ctx.patient.age })) + ")", 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("platform.common.address")), 1),
              _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.patient.address?.formatted_address), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _createVNode(_component_BaseTextInput, {
              label: _ctx.$t('custom.uhb.consult.clinic'),
              "model-value": _ctx.consult.clinic_configuration.name,
              class: "text-base font-medium",
              readonly: "",
              disabled: ""
            }, null, 8, ["label", "model-value"]),
            _createVNode(_component_BaseTextInput, {
              label: _ctx.$t('custom.uhb.consult.consult-type'),
              "model-value": _ctx.consult.consult_type?.name || '',
              class: "text-base font-medium",
              readonly: "",
              disabled: ""
            }, null, 8, ["label", "model-value"])
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createVNode(_component_BaseTextInput, {
              label: _ctx.$t('custom.uhb.consult.primary-rejected-reason'),
              "model-value": _ctx.$t(`custom.uhb.consult.reject-reasons.${_ctx.consult.rejected_reason_type}`),
              class: "text-base font-medium",
              readonly: "",
              disabled: ""
            }, null, 8, ["label", "model-value"]),
            (_ctx.consult.rejected_reason)
              ? (_openBlock(), _createBlock(_component_BaseTextarea, {
                  key: 0,
                  "model-value": _ctx.consult.rejected_reason,
                  class: "text-base font-medium",
                  readonly: "",
                  disabled: ""
                }, null, 8, ["model-value"]))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_ResolveReason, {
            modelValue: _ctx.resolveReason,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.resolveReason) = $event)),
            onSelectReason: _ctx.onChange,
            onUpdateErrorLimitChar: _ctx.updateError
          }, null, 8, ["modelValue", "onSelectReason", "onUpdateErrorLimitChar"]),
          (_ctx.error)
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(_ctx.error), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_31, [
          _createVNode(_component_BaseButton, {
            class: "ml-auto mr-2",
            color: "ghost",
            disabled: _ctx.loading,
            onClick: _ctx.close
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("platform.common.cancel")), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
            "data-dusk": "resolve-button",
            class: "mr-0",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.markAsResolved()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("custom.uhb.consult.rejected-resolve-button")), 1)
            ]),
            _: 1
          })), [
            [_directive_allow, 'consult:resolve']
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["onClose"]))
}